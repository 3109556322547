<template>
  <div class="singup-certify wrapper2">
    <div class="inner-wrapper2">
      <div class="signup-certify__form accounts-form">
        <div class="accounts-form-wrapper">
          <h1
            class="accounts-form__small-bi-header cursor"
            @click="$router.push('/introduction/main')"
          >
            Qpick
          </h1>
          <h1 class="accounts-form__header">이메일 인증</h1>
          <div class="accounts-form__description">
            입력하신 이메일 주소로 인증 메일이 전송되었습니다.<br />
            인증 메일 본문 내용에 “인증하기” 버튼을 클릭하여 인증해주세요.

            <div class="accounts-form__subdescription">
              이메일 인증하기를 2일 이내 인증 완료하지 않을 경우<br />
              다시 회원가입을 진행해주셔야 합니다.
            </div>
          </div>
          <p class="accounts-infobox">
            <span>아이디</span>
            <span class="break-all">{{ email }}</span>
          </p>
        </div>
      </div>
      <div class="signup-certify__footer accounts-footer">
        <div class="accounts-footer__form">
          <div class="accounts-footer__form_inner_mobile">
            <p class="signup-certify__footer--paragraph">
              인증 메일을 받지 못하셨나요?<br />
              입력한 이메일 주소를 확인하시고 다시 전송해보세요.
            </p>
            <button class="accounts-footer__button-transparent" @click="resend">
              인증 이메일 다시 보내기
            </button>
            <button
              class="
                accounts-footer__button-transparent
                accounts-footer__button-callback
              "
              @click="sendCallback"
              v-show="showCallbackButton"
            >
              {{ callback_name }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <toastMsg ref="toast"></toastMsg>
  </div>
</template>

<script>
import userAPI from "@/api/accounts/user.js";
import "@/assets/scss/accounts/basis.scss";
import {getAuthToken, removeAuthToken} from "@/utils/auth";
import {createNamespacedHelpers} from "vuex";
const ModuleAuth = createNamespacedHelpers("ModuleAuth");

export default {
  data() {
    return {
      email: "",
      callback_url: "",
      callback_name: "돌아가기",
      showCallbackButton: false
    };
  },
  methods: {
    resend() {
      userAPI.certifyMailResend(this.email).then(res => {
        switch (res.status) {
          case 204:
            this.$refs.toast.show("인증 이메일이 다시 전송되었습니다.");
            break;

          default:
            this.$refs.toast.show(res.data.errors[0].userMsg);
            break;
        }
      });
    },
    sendCallback() {
      if (this.callback.match(/^http(s*):\/\//)) {
        location.href = this.callback_url;
      } else {
        this.$router.push(this.callback_url);
      }
    },
    // 로그아웃
    userLogout() {
      this.actLogout().then(() => {
        removeAuthToken();
        localStorage.removeItem("userId");
        this.setLogged({});
        this.setLoginStatus(false);
      });
    },
    ...ModuleAuth.mapActions(["actLogout"]),
    ...ModuleAuth.mapMutations(["setLogged", "setLoginStatus"])
  },
  mounted() {
    let callback_url = sessionStorage.getItem("accounts_signup_callback_url");
    let callback_name = sessionStorage.getItem("accounts_signup_callback_name");

    if (typeof callback_url == "string" && callback_url.length > 0) {
      this.callback_url = callback_url;
      this.callback_name = callback_name;
      this.showCallbackButton = true;
    }

    this.email = this.$router.currentRoute.query.email;

    //준회원 -> 정회원 전환시 큐픽 준회원 상태로 이용 불가능 처리
    if (getAuthToken()) {
      this.userLogout();
    }
  },
  components: {
    toastMsg: () => import("@/components/accounts/toast-message.vue")
  }
};
</script>

<style>
.accounts-footer {
  position: relative !important;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .accounts-form {
    background-color: #f7f9fc;
    margin: 0;
    width: 100%;
    max-width: none;
  }
  .accounts-form-wrapper {
    margin: auto;
    max-width: 380px;
  }
  .signup-certify__form {
    padding: 0 16px;
    box-sizing: border-box;
    background: #fff;
  }
  .inner-wrapper2 {
    min-height: 100vh;
    background: #f7f9fc;
  }
  .accounts-footer__form {
    background: #f7f9fc;
    max-width: none;
  }
  .accounts-footer__form_inner_mobile {
    max-width: 380px;
    padding: 0 16px;
    margin: auto;
  }
  .signup-certify__footer {
    background: #f7f9fc;
  }
}
.signup-certify__form {
  padding-bottom: 42px;
}
.accounts-footer__form {
  padding: 42px 0;
}
.signup-certify__footer--paragraph {
  margin-bottom: 16px;
}
.accounts-form__subdescription {
  margin-top: 12px;
  color: #8d8d8d;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.005em;
}
</style>
