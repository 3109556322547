var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "singup-certify wrapper2"
  }, [_c('div', {
    staticClass: "inner-wrapper2"
  }, [_c('div', {
    staticClass: "signup-certify__form accounts-form"
  }, [_c('div', {
    staticClass: "accounts-form-wrapper"
  }, [_c('h1', {
    staticClass: "accounts-form__small-bi-header cursor",
    on: {
      "click": function click($event) {
        return _vm.$router.push('/introduction/main');
      }
    }
  }, [_vm._v(" Qpick ")]), _c('h1', {
    staticClass: "accounts-form__header"
  }, [_vm._v("이메일 인증")]), _vm._m(0), _c('p', {
    staticClass: "accounts-infobox"
  }, [_c('span', [_vm._v("아이디")]), _c('span', {
    staticClass: "break-all"
  }, [_vm._v(_vm._s(_vm.email))])])])]), _c('div', {
    staticClass: "signup-certify__footer accounts-footer"
  }, [_c('div', {
    staticClass: "accounts-footer__form"
  }, [_c('div', {
    staticClass: "accounts-footer__form_inner_mobile"
  }, [_vm._m(1), _c('button', {
    staticClass: "accounts-footer__button-transparent",
    on: {
      "click": _vm.resend
    }
  }, [_vm._v(" 인증 이메일 다시 보내기 ")]), _c('button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showCallbackButton,
      expression: "showCallbackButton"
    }],
    staticClass: "\n              accounts-footer__button-transparent\n              accounts-footer__button-callback\n            ",
    on: {
      "click": _vm.sendCallback
    }
  }, [_vm._v(" " + _vm._s(_vm.callback_name) + " ")])])])])]), _c('toastMsg', {
    ref: "toast"
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accounts-form__description"
  }, [_vm._v(" 입력하신 이메일 주소로 인증 메일이 전송되었습니다."), _c('br'), _vm._v(" 인증 메일 본문 내용에 “인증하기” 버튼을 클릭하여 인증해주세요. "), _c('div', {
    staticClass: "accounts-form__subdescription"
  }, [_vm._v(" 이메일 인증하기를 2일 이내 인증 완료하지 않을 경우"), _c('br'), _vm._v(" 다시 회원가입을 진행해주셔야 합니다. ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "signup-certify__footer--paragraph"
  }, [_vm._v(" 인증 메일을 받지 못하셨나요?"), _c('br'), _vm._v(" 입력한 이메일 주소를 확인하시고 다시 전송해보세요. ")]);
}]

export { render, staticRenderFns }